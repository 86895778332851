<template>
  <div id="inside">
    <div class="container">
      <div class="container">
        <div class="row">
          <div class="col-12 text-body px-2 mb-2">
            <button
              class="justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 pl-1 text-primary"
              @click="home()"
            >
              <i class="fa fa-chevron-left fa-fw pull-left text-info"></i>
              Voltar
            </button>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row" v-if="debtsCdc && !loadOffers">
          <div class="col-12 text-body pr-0">
            <h5 class="text-bold mt-2">Você está negociando</h5>
            <HeaderOptions />
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="debtsCdc && !loadOffers">
      <div class="col-12">
        <div class="card pt-4 pb-4">
          <div class="col-12">
            <h5 class="mb-0 d-flex align-items-center">
              {{ debtsCdc[0].product }}
            </h5>
            <p class="mb-1" style="font-size: 8pt">
              Contrato:
              {{ debtsCdc[0].contract }}
            </p>

            <hr class="" />
          </div>
          <div class="d-flex col-lg-12 col-12">
            <p class="mr-2 mb-0">Valor total para negociação:</p>
            <p class="text-bold mb-0" style="font-weight: bold">
              R$
              {{
                debtsCdc[0].originalValue.toLocaleString("pt-BR", {
                  currency: "BRL",
                  minimumFractionDigits: 2,
                })
              }}
            </p>
          </div>
          <div class="d-flex col-lg-12 col-12">
            <p class="mr-2" style="font-size: 10pt">
              Data da parcela mais antiga:
            </p>
            <p class="text-bold" style="font-weight: bold">
              {{ formatData(debtsCdc[0].dueDate, "YYYY-MM-DD", "L") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="debtsCdc && !loadOffers">
      <div class="row">
        <div class="container">
          <div class="col-12 text-body pr-0">
            <h5 class="text-bold mt-3" style="color: black">
              Selecione as parcelas a negociar
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div
      v-bind:class="[largura < 500 ? 'container' : 'container']"
      v-for="(value, key, index) in debtsCdc"
      :key="`${key}-${index}`"
    >
      <div v-bind:class="[largura < 500 ? 'card mb-4 m-2' : 'card mr-3 ml-3']">
        <!-- <div class="card-header">
          <div class="row">
            <div class="col-12">
              <p class="ml-2 mt-2" style="font-weight: bold; color: black">
                Pagamento à vista
              </p>
            </div>
          </div>
          <hr class="mt-0" />
        </div> -->
        <div class="card-body mt-0 pt-0">
          <div class="row">
            <div class="col-12">
              <p class="ml-1" style="font-weight: bold; color: black">
                Parcelas
              </p>
            </div>
            <div class="col-12">
              <p
                class="mb-2 ml-1"
                style="font-style: italic; color: black; font-size: 12px"
              >
                Selecione as parcelas que deseja negociar
              </p>
            </div>
          </div>
          <div class="row mt-4">
            <div
              class="col-lg-12 d-flex align-items-center col-12"
              style="justify-content: end; font-style: italic"
            >
              <p class="mr-2 mt-1 mb-1 small">
                Selecionar todas as parcelas disponíveis
              </p>
              <input
                type="checkbox"
                v-model="value.selected"
                :disabled="thereIsAgreementCdcClosed(value.contract)"
                @change="selectAllParcelsContract(value)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-12 mb-3" style="">
              <div class="col-12" v-if="largura > 500">
                <div
                  class="row"
                  style="
                    background-color: white;
                    min-height: 10px;
                    margin-bottom: -2px;
                  "
                ></div>
              </div>
              <div class="col-12" v-if="largura < 500">
                <div
                  class="row"
                  style="
                    background-color: white;
                    min-height: 15px;
                    margin-bottom: -10px;
                  "
                ></div>
              </div>
              <div
                class="table-responsive"
                :style="
                  value.installments.length > 4
                    ? 'height: 14rem;'
                    : 'height: 12rem; overflow-y: auto;'
                "
              >
                <div class="table-container">
                  <div class="custom-table">
                    <div class="table-row table-header">
                      <div class="table-cell header">Selecionar</div>
                      <div
                        v-bind:class="
                          largura > 500
                            ? 'table-cell header text-center'
                            : 'table-cell header'
                        "
                      >
                        Nº Parcela
                      </div>
                      <div class="table-cell header">Vencimento</div>
                      <div class="table-cell header text-center">Valor</div>
                    </div>
                    <div
                      v-bind:class="largura < 500 ? 'table-row mr-2' : 'table-row'"
                      v-for="(value1, key1, index1) in value.installments"
                      :key="`${key1}-${index1}`"
                    >
                      <div class="table-cell">
                        <input
                          id="checkParcels"
                          type="checkbox"
                          v-model="checkedParcels"
                          :value="value1"
                          :disabled="thereIsAgreementCdcClosed(value.contract)"
                          @change="calculateSingleInstallment(value1, value)"
                        />
                      </div>
                      <div class="table-cell text-center">
                        {{ value1.installmentNumber }}
                      </div>
                      <div class="table-cell text-center">
                        {{ formatData(value1.dueDate, "YYYY-MM-DD", "L") }}
                      </div>
                      <div
                        v-bind:class="
                          largura > 500
                            ? 'table-cell text-center'
                            : 'table-cell'
                        "
                      >
                        {{
                          formatFloatToReal(
                            parseFloat(value1.originalValue, 10)
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="d-flex col-12 col-lg-12"
              style="align-items: center; font-style: italic"
            >
              <p class="mb-0 mr-2">Total de parcelas selecionadas:</p>
              <h6 class="mt-2">
                <b>{{
                  value.selected || value.parcelsIsFromCurrentContract
                    ? checkedParcels.length
                    : 0
                }}</b>
              </h6>
            </div>
            <div
              class="d-flex col-12 col-lg-12"
              style="align-items: center; font-style: italic"
            >
              <p class="mb-1 mr-2">Valor total selecionado:</p>
              <h6 class="mt-1">
                <b>{{
                  value.selected || value.parcelsIsFromCurrentContract
                    ? sumByContract(checkedParcels)
                    : "R$0,00"
                }}</b>
              </h6>
            </div>
          </div>
          <div class="row mt-3">
            <div class="text-center col-md-12 mt-1">
              <button
                :class="{
                  'alert-flashing': checkedParcels.length > 0 && needUpdate,
                }"
                style="
                  width: 100%;
                  border-radius: 20px;
                  background-color: #e7ebfd;
                  padding: 10px;
                "
                :disabled="
                  (checkedParcels.length <= 0 && !needUpdate) ||
                  thereIsAgreementCdcClosed(value.contract)
                "
                v-if="!loadOffers"
                @click="loadOffersCdc()"
              >
                Atualizar valores
              </button>
              <div class="container" v-if="loadOffers || loadingMoreOptions">
                <ProgressLoading
                  v-bind:loading="loadOffers || loadingMoreOptions"
                />
              </div>
            </div>
          </div>
          <hr />
          <div v-if="cardOffers && !loadOffers && !needUpdate" id="cardOfferUpdated">
            <div
              class="row mt-3"
              v-for="(value1, key2, index2) in cardOffers.paymentOptions"
              :key="`${key2}-${index2}`"
            >
              <div class="d-flex flex-column col-8">
                <div class="text-info" style="display: flex; margin-bottom: 0">
                  <p class="ml-2 mb-0">Valor total do acordo:</p>
                </div>
                <div class="text-info" style="display: flex; margin-bottom: 0" v-if="value1.discount > 0">
                  <p class="ml-2 mb-0">De <span style="text-decoration: line-through; margin-bottom: 0">{{ sumByContract(checkedParcels) }}</span> por</p> 
                </div>
                
                <div
                  class="ml-2 mb-0"
                  style="margin-bottom: 0; font-weight: bold"
                >
                  <p class="mb-0" style="font-size: 18px">
                    R$
                    {{
                      value1.value.toLocaleString("pt-BR", {
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      })
                    }}
                  </p>
                </div>
                <div class="mb-0" style="margin-bottom: 0">
                  <p class="ml-2" style="font-size: 9pt; margin-bottom: 0">
                    Vencimento do acordo:
                    {{ formatData(cardOffers.dueDate, "YYYY-MM-DD", "L") }}
                  </p>
                </div>
              </div>
              <div v-bind:class="largura < 500 ? 'col-4 text-center' : 'col-4 text-right pr-5'" v-if="value1.discount > 0">
                <p class="mb-0" style="font-size: 9pt">Desconto de</p>
                <span
                  class="text-white"
                  style="
                    display: inline-block;
                    background-color: #88cd31;
                    border-radius: 20px;
                    padding: 5px 5px;
                    text-align: center;
                    width: auto;
                    font-size: 14pt;
                  "
                >
                  {{ value1.discount }}%
                </span>
              </div>
              <div class="text-center col-md-12 mt-3">
                <button
                  class="btn mb-2 btn-primary p-3"
                  style="width: 100%; border-radius: 20px"
                  :disabled="
                    needUpdate || thereIsAgreementCdcClosed(value.contract)
                  "
                  @click="resumoAcordo(value1, false)"
                >
                  PAGAR À VISTA
                </button>
              </div>
            </div>
          </div>
          <div
            class="row mt-3 mb-4 px-2"
            v-if="thereIsAgreementCdcClosed(value.contract)"
          >
            <div class="col-lg-1 text-center px-1 col-1">
              <i class="fa fa-info-circle text-danger"></i>
            </div>
            <div class="px-1 col-lg-11 col-11">
              <p class="small">
                Existe um acordo em andamento, por isso não é possível fazer uma
                nova negociação.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="!debtsCdc && !loadOffers">
      <div class="row">
        <div
          class="align-items-center justify-content-center col-sm-12 col-12 d-flex col-md-12 col-lg-12 px-4"
        >
          <i
            class="fa fa-2x fa-exclamation-circle pull-left mr-4 mb-2 text-primary"
          ></i>
          <h4 class="py-4 text-primary">
            No momento não temos uma oferta disponível para você.
          </h4>
        </div>
      </div>
      <div class="row pb-5">
        <div
          class="align-items-center justify-content-center col-sm-12 col-12 d-flex col-md-12 col-lg-12 px-4"
        >
          <h6 class="py-2 text-muted">
            Conte com a gente para ter um alívio financeiro! É só selecionar o
            produto e acionar o atendimento que preferir:
          </h6>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12 col-lg-3 px-4">
          <h4 class="py-4">Central de Relacionamento Cartão de Crédito</h4>
          <p>
            <a href="tel:40043600" target="_blank">4004 3600</a> - Capitais e
            regiões metropolitanas.&nbsp;<br /><a
              href="tel:08007277477"
              target="_blank"
              >0800 727 7477</a
            >
            - Demais localidades.&nbsp;<br /><a
              href="tel:551133663216"
              target="_blank"
              >+55 11 3366 3216</a
            >
            - Atendimento no exterior.&nbsp;<br />De segunda a sexta-feira, das
            8h às 18h30.
          </p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">
            Central de Relacionamento Cartão de Crédito Empresarial
          </h4>
          <p>
            <a href="tel:1140043600" target="_blank">4004 3600</a> - Capitais e
            regiões metropolitanas.<br /><a
              href="tel:08007277477"
              target="_blank"
              >0800-727-7477</a
            >
            - Demais localidades.&nbsp;<br />De segunda à sexta das 7h40 às
            19h00.
          </p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">
            Central de Relacionamento Empréstimo e Financiamento
          </h4>
          <p>
            <a href="tel:40045215" target="_blank">4004 5215</a>&nbsp;<br />De
            segunda a sexta-feira, das 8h às 19h (exceto feriados).
          </p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">SAC</h4>
          <p>
            <a href="tel:08007272745" target="_blank">0800 727 2745</a
            ><br />Atendimento 24 horas.
          </p>
        </div>
      </div>
    </div>

    <div
      class="container"
      v-if="cardOffers && !loadOffers"
      :style="largura < 500 ? 'padding-left: 25px;' : ''"
    >
      <div class="row" v-if="!loadOffers"></div>
    </div>
    <!-- <div class="container" v-if="loadOffers || loadingMoreOptions">
      <ProgressLoading v-bind:loading="loadOffers || loadingMoreOptions" />
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  formatCurrencieValueBRL as formatValue,
  formatData,
  truncateString,
  formatFloatToReal,
} from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
import HeaderOptions from "../components/HeaderMenu.vue";
import { VMoney } from "v-money";
import { initializeGlobals } from "@/global";

export default {
  name: "propostas-cdc",
  components: {
    ProgressLoading,
    HeaderOptions,
  },
  directives: {
    money: VMoney,
  },
  data() {
    return {
      form: {
        offerSelected: null,
      },
      loadOffers: false,
      loadOffersWithEntry: false,
      offers: [],
      agreementInfo: [],
      largura: null,
      installment: {},
      loadSetAgreement: false,
      cardOffers: null,
      otherOptionsInstallment: null,
      newOffersLoaded: false,
      othersOptionsLoaded: false,
      selectedOption: {},
      selectedOptionEntryValue: {},
      paymentOptionsId: [],
      isContencioso: false,
      entryValue: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      responseWithEntryValue: [],
      entryValueFinal: null,
      loadingMoreOptions: false,
      lastOptionCalled: null,
      count: 0,
      userDataStorage: {},
      pageLocation: null,
      protocol: null,
      path: null,
      host: null,
      userAdm: {},
      ipUser: null,
      debtsCdc: [],
      checkedParcels: [],
      needUpdate: false,
      agreements: [],
      installmentsSelected: [],
    };
  },
  methods: {
    formatValue,
    formatData,
    truncateString,
    formatFloatToReal,

    ...mapActions("meusContratos", ["ActionGetOffersPortoSeguro"]),

    async loadOffersCdc() {
      //eslint-disable-next-line
      // debugger;
      this.loadOffers = true;
      // if (this.$route.params.from === "resumoAcordo") {
      //   this.agreementInfo = this.$route.params.agreementInfo;
      // } else {
      //   this.agreementInfo = this.$route.params.agreementInfo;
      // }

      try {
        this.installmentsSelected = this.checkedParcels.map((value) => {
          return {
            dueDate: value.dueDate,
            installmentNumber: value.installmentNumber,
          };
        });

        var result = await this.ActionGetOffersPortoSeguro({
          clientCode: this.agreementInfo.contract,
          credor: this.agreementInfo.credor,
          installmentsOptions: [1],
          agreementType: "ParcelamentoCDC",
          contract: this.agreementInfo.contract,
          discount: 0,
          inputValue: 0,
          paymentType: "Boleto",
          contracts: [
            {
              contract: this.agreementInfo.contract,
              product: this.agreementInfo.product,
              installments: this.installmentsSelected,
            },
          ],
          tag: localStorage.getItem("tag"),
          daysDelay: this.agreementInfo.daysDelay,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
          paymentValue: this.sumTotalInstallmentsCdc,
        });


        

        this.cardOffers = await result.data.results;
        this.loadOffers = false;
        this.needUpdate = false;
      } catch (error) {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          position: "top right",
          title: "Erro",
          message:
            "Não foi possível buscar ofertas, tente novamente mais tarde!",
        });
        this.eventCallbackNegociarFailureGTM(error);
        // this.$router.push({
        //   name: "dashboard",
        // });
      } finally {
        setTimeout(() => {
          this.goToCardOfferUpdated()
        }, 1000);
        this.loadOffers = false;
      }
    },

    async resumoAcordo(offer, isWithEntryValue) {
      isWithEntryValue
      if (offer === null) {
        offer = this.selectedOption;
      }

      this.eventClickNegociarGTM(offer);

      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard/resumoAcordoCdc",
        "resumo-acordo"
      );
      this.updateCustomData();

      this.offerInfoOriginal = this.agreementInfo;

      this.$router.push({
        name: "resumoAcordoCdc",
        params: {
          offerInfo: {
            clientCode: this.agreementInfo.clientCode,
            credor: this.agreementInfo.credor,
            product: this.agreementInfo.product,
            dueDate: this.cardOffers.dueDate,
            contract: this.agreementInfo.contract,
            paymentOptionCode: this.cardOffers.paymentOptionCode,
            paymentOptionsCodeIds: this.paymentOptionsId,
            paymentValue: this.agreementInfo.paymentValue,
            entryValue: isWithEntryValue ? this.entryValueFinal : 0,
            agreementType: "ParcelamentoCDC",
            plan: offer.plan,
            ...offer,
          },
          agreementInfo: this.agreementInfo,
          cardOffers: this.cardOffers,
          debtsCdc: this.debtsCdc,
          isWithEntryValue: isWithEntryValue,
          sumTotalInstallmentsCdc: this.sumTotalInstallmentsCdc,
          installmentsSelected: this.installmentsSelected,
          // totalInstallmentsCdcWithFees: this.totalInstallmentsCdcWithFees,
        },
      });
    },

    thereIsAgreementCdcClosed(contractNumber) {
      if (!contractNumber || this.agreements.length <= 0) {
        return;
      }
      var result =
        this.agreements.filter(function (val) {
          return val.contract === contractNumber;
        }).length > 0;

      return result;
    },

    sumByContract(checkedParcels) {
      let total = 0;
      let totalWithFees = 0;
      checkedParcels.map((valueInstallment) => {
        total += parseFloat(valueInstallment.originalValue);
        totalWithFees += parseFloat(valueInstallment.paymentValue);
      });
      this.sumTotalInstallmentsCdc = total;
      this.totalInstallmentsCdcWithFees = totalWithFees;
      return total
        .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
        .replace("-", "");
    },

    selectAllParcelsContract(contract) {
      this.needUpdate = true;
      this.clearFlagsAnotherContracts(contract);

      if (contract.selected) {
        this.checkedParcels = [];
        this.checkedParcels = [
          ...this.checkedParcels,
          ...contract.installments,
        ];
      } else {
        this.checkedParcels = this.checkedParcels.filter(
          (parcel) => !contract.installments.includes(parcel)
        );
      }

      this.checkedParcels.map((parcel) => {
        this.valueTotal += parseFloat(parcel.originalValue);
      });
    },

    clearFlagsAnotherContracts(contract) {
      if (!contract) {
        return;
      }
      this.debtsCdc.map((item) => {
        if (item.contract !== contract.contract) {
          item.selected = false;
          item.parcelsIsFromCurrentContract = false;
        }
      });
    },

    async calculateSingleInstallment(installment, contract) {
      if (this.checkedParcels.length <= 0) {
        this.clearFlagsAnotherContracts();
        return;
      }
      this.thereIsThisInstallmentInTheCheckedParcels(contract);
      this.valueTotal = 0;
      var orderCorrect = await this.controllerRuleByTypeCheckbox(contract);

      if (!orderCorrect) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Aviso",
          message: "Selecione parcela(s) em sequência!",
        });

        if (this.checkedParcels.length <= 0) {
          this.clearFlagsAnotherContracts();
        }

        if (
          parseInt(
            this.checkedParcels[this.checkedParcels.length - 1]
              .installmentNumber
          ) === installment.installmentNumber
        ) {
          let currentInstallment = this.checkedParcels;
          let index = currentInstallment.indexOf(installment);
          if (index > -1) {
            currentInstallment.splice(index, 1);
          }
          return;
        } else {
          this.checkedParcels.push(installment);
        }
        return;
      } else {
        this.needUpdate = true;
        this.checkedParcels.map((value) => {
          this.valueTotal += parseFloat(value.originalValue, 10);
        });
      }
    },

    thereIsThisInstallmentInTheCheckedParcels(value) {
      this.checkedParcels.map((parcels) => {
        if (value.installments.includes(parcels)) {
          value.parcelsIsFromCurrentContract = true;
        }
      });
    },

    controllerRuleByTypeCheckbox(contract) {
      if (this.checkedParcels.length < 1) {
        return false;
      }

      const sortedCheckedParcels = this.checkedParcels.sort((a, b) => {
        return parseInt(a.installmentNumber) - parseInt(b.installmentNumber);
      });

      // Verificar se a seleção começa pela parcela mais baixa
      if (
        parseInt(sortedCheckedParcels[0].installmentNumber) !==
        contract.installments[0].installmentNumber
      ) {
        return false;
      }

      for (let i = 0; i < sortedCheckedParcels.length - 1; i++) {
        const currentNumber = parseInt(
          sortedCheckedParcels[i].installmentNumber
        );
        const nextNumber = parseInt(
          sortedCheckedParcels[i + 1].installmentNumber
        );

        if (
          nextNumber !== currentNumber + 1 &&
          nextNumber !== currentNumber &&
          currentNumber !== nextNumber + 1
        ) {
          return false;
        }
      }

      return true;
    },

    home() {
      this.eventClickArrowToBackGTM();
      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard",
        "Dashboard"
      );
      this.updateCustomData();
      this.$router.push({
        name: "dashboard",
      });
    },

    buildAndSetUserData(doc, id, pageLocation, pageName) {
      // const pageName = this.$options.name;
      const userData = {
        cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
        id: id, // Substitua pelo ID real
        page_location: pageLocation,
        pageName: pageName,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
    },

    eventClickArrowToBackGTM() {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "click:button:voltar";

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    eventClickNegociarGTM(offer) {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "click:button:negociar";

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: this.buildPayloadOfferChoose(offer),
        product_identify: this.agreementInfo.contract,
        product_category: this.agreementInfo.product,
        product_user_profile: "titular",
        brand: "porto",
        product: this.agreementInfo.product,
        vertical: "bank",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    buildPayloadOfferChoose(offer) {
      var formatedPayload = null;
      if (offer.installmentsNumber === 1) {
        formatedPayload = "pagamento-a-vista";
      } else {
        formatedPayload = `parcelado-em-${offer.installmentsNumber}-vezes`;
      }
      return formatedPayload;
    },

    eventCallbackNegociarFailureGTM(error) {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction = "solicitar-opcoes-pagamento:alert";
      const eventLabel = truncateString(
        "Não foi possível buscar ofertas, tente novamente mais tarde!",
        100
      );
      const eventCode = error.status;
      const eventErrorService = "";
      const eventAlertService = truncateString(error.data.message, 100);
      const eventAlert = "select_content";
      const eventAlertType = "erro";
      const eventData = {
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: eventLabel,
        alert_code: eventCode,
        error_service: eventErrorService,
        alert_service_message: eventAlertService,
        alert_event: eventAlert,
        alert_type: eventAlertType,
      };

      window.dataLayer.push({ event: "alert", ...eventData });
    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    },

    async goToCardOfferUpdated() {
      const cardOfferDiv = document.getElementById("cardOfferUpdated");
      if (cardOfferDiv) {
        cardOfferDiv.scrollIntoView();
        cardOfferDiv.setAttribute("href", "#cardOfferUpdated");
      }
    },
  },
  async created() {
    //eslint-disable-next-line
    // debugger;
    this.updateCustomData();
    this.ipUser = localStorage.getItem("ipUser");
    localStorage.setItem("currentPageName", this.$options.name);
    this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
    this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.path = window.location.pathname;
    this.pageLocation = this.protocol + "//" + this.host + this.path;
    this.largura = window.screen.width;
    this.debtsCdc = this.$route.params.debtsCdc;
    this.agreements =
      typeof this.$route.params.agreements === "undefined"
        ? []
        : this.$route.params.agreements;
    this.agreementInfo = this.$route.params.agreementInfo;
    this.installmentsSelected = this.$route.params.installmentsSelected;
    this.sumTotalInstallmentsCdc = this.$route.params.sumTotalInstallmentsCdc;

    if (
      typeof this.installmentsSelected !== "undefined" &&
      this.installmentsSelected.length > 0
    ) {
      this.debtsCdc[0].installments.map((item) => {
        this.installmentsSelected.map((installment) => {
          if (
            parseInt(item.installmentNumber) ===
            parseInt(installment.installmentNumber)
          ) {
            this.checkedParcels.push(item);
          }
        });
      });
      this.$forceUpdate();
    }
    // this.totalInstallmentsCdcWithFees =
    //   this.$route.params.totalInstallmentsCdcWithFees;
  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>

<style scoped>
/* @media (max-width: 500px) {
  .card {
    margin-right: 0;
  }
} */

input[type="checkbox"] {
  /* appearance: none; */
  width: 20px;
  height: 20px;
  border: 3px solid #9ea7b1;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #6e9ebf;
  border: none;
  /* position: relative; */
}

input[type="checkbox"]:checked::before {
  /* Checkmark */
  content: "\2713";
  position: absolute;
  top: -2px;
  left: 3px;
  font-size: 18px;
  color: white;
}

.alert-flashing {
  animation: alert-flash 0.5s infinite alternate;
  width: 100%;
}

@keyframes alert-flash {
  0% {
    background-color: #e7ebfd;
    color: #0046c0;
  }
  100% {
    background-color: #a2b1d8;
    /* color: #fff; */
  }
}

.table-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdcdc;
  font-family: Arial, sans-serif;
  background-color: #eff0f2;
}

.table-row {
  display: flex;
  /* align-items: center; */
  border-bottom: 1px solid #eff0f2;
}

.table-header {
  background-color: #eff0f2;
  font-weight: bold;
  padding-top: 15px;
}

.table-cell {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  flex: 1;
  font-size: 9pt;
  color: black;
}

.table-cell.header {
  color: black;
  font-size: 8pt;
  font-weight: bold;
}

.table-cell:nth-child(1),
.table-cell:nth-child(3) {
  text-align: center;
}

.table-cell:nth-child(4) {
  /* font-weight: bold; */
  text-align: right;
}

.table-cell input[type="checkbox"] {
  width: 20px;
  height: 20px;
  accent-color: #0044cc; /* Azul para os checkboxes */
}

.table-row:hover {
  background-color: #f1f5fb;
}

.alert-flashing {
  animation: alert-flash 0.5s infinite alternate;
}

/* @keyframes alert-flash {
  from {
    background-color: #ffeb3b;
  }
  to {
    background-color: #ff9800;
  }
} */

@media (max-width: 500px) {
  .custom-table .table-cell {
    font-size: 10px;
    padding: 3px;
  }
}
</style>
